import { Controller } from "@hotwired/stimulus";
import Logger from "./logger";

import { Dexie, DexieOptions } from "dexie";
import {
  indexedDB as fakeIndexedDB,
  IDBKeyRange as fakeIDBKeyRange,
} from "fake-indexeddb";
import { csrfToken } from "../lib/util";

export class Database extends Dexie {
  playbackEvents!: Dexie.Table<PlaybackEvent, string>;
  constructor(options?: DexieOptions | undefined) {
    super("playback_events", options);
    this.version(1).stores({
      playbackEvents: "id, began_at, active, queued",
    });
  }
}

export interface TrackingEventDetail {
  id: string;
  tape_id: string;
  playback_session_id: string;
  began_at: Date;
  ended_ms: number;
  active: 0 | 1;
}

export interface PlaybackEvent {
  id: string;
  tape_id: string;
  playback_session_id: string;
  began_at: Date;
  began_ms: number;
  ended_ms: number;
  active: 0 | 1; // NOTE: we can't have booleans in IndexedDB
  queued: 0 | 1; // NOTE: we can't have booleans in IndexedDB
}

const REPORTING_RATE_MS = 5 * 1e3;

export default class extends Controller {
  log = new Logger("Tracker");
  db!: Database;
  reportingTimeout: number | null = null;

  async connect() {
    await this.initDb();
    this.startReporting();
  }

  async initDb() {
    try {
      await this.initOnDiskDB();
    } catch (onDiskDBInitErr) {
      if (!this.isInitDBErr(onDiskDBInitErr)) throw onDiskDBInitErr;
      window.Rollbar.warning("On-disk IndexedDB initialization failed");

      try {
        await this.initInMemoryDB();
      } catch (inMemoryDBInitErr) {
        if (!this.isInitDBErr(inMemoryDBInitErr)) throw inMemoryDBInitErr;
        window.Rollbar.error("In-memory IndexedDB initialization failed");

        // TODO: should we just renounce tracking?
      }
    }
  }

  async initOnDiskDB() {
    this.log.log("Initializing on-disk IndexedDB");
    this.db = new Database();

    this.log.log("Opening connection");
    await this.db.open();
  }

  async initInMemoryDB() {
    this.log.log("Initializing in-memory IndexedDB");
    this.db = new Database({
      indexedDB: fakeIndexedDB,
      IDBKeyRange: fakeIDBKeyRange,
    });

    this.log.log("Opening connection");
    await this.db.open();
  }

  isInitDBErr(e: unknown) {
    return e instanceof Dexie.DexieError && e.name == "InvalidStateError";
  }

  disconnect() {
    this.stopReporting();
  }

  startReporting() {
    this.performReporting();
    this.reportingTimeout = setInterval(
      this.performReporting.bind(this),
      REPORTING_RATE_MS
    );
  }

  async stopReporting() {
    if (this.reportingTimeout === null) return;
    clearInterval(this.reportingTimeout);
    await this.performReporting();
  }

  async performReporting() {
    // NOTE: this MUST be idempotent

    const enqCount = await this.db.playbackEvents
      .where({ queued: 0 })
      .modify({ queued: 1 });
    this.log.info(enqCount, "new events enqueued");

    const pes = await this.db.playbackEvents.where({ queued: 1 }).toArray();

    if (pes.length < 1) return;

    const playback_events = pes.map(
      ({ playback_session_id, id, tape_id, began_at, began_ms, ended_ms }) => ({
        id,
        playback_session_id,
        tape_id,
        began_at,
        began_ms,
        ended_ms,
      })
    );

    this.log.info("Reporting", pes.length, "queued events");
    const received = await fetch(`/tapes/listen`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ playback_events }),
    })
      .then((response) => response.ok)
      .catch((error) => this.log.error(error));

    if (received) {
      const delCount = await this.db.playbackEvents
        .where({ queued: 1, active: 0 })
        .delete();
      this.log.info(delCount, "inactive events deleted from queue");
    } else {
      this.log.warn("Reporting failed");
      // const count = await this.db.playbackEvents
      //   .where({ queued: 1 })
      //   .modify({ queued: 0 });
      // this.log.info(count, "events dequeued");
    }
  }

  async handleTrackingEvent({
    detail: { id, tape_id, playback_session_id, began_at, ended_ms, active },
  }: CustomEvent<TrackingEventDetail>) {
    this.log.debug("Handling trackingEvent");
    await this.db.transaction("rw", this.db.playbackEvents, () => {
      this.db.playbackEvents
        .update(id, { ended_ms, active })
        .then((updated) => {
          if (updated) return; // else, we need to create a new record
          this.db.playbackEvents
            .filter((pe) => pe.began_ms == pe.ended_ms)
            .delete();
          this.db.playbackEvents.where({ active: 1 }).modify({ active: 0 });
          this.db.playbackEvents.add({
            id: id,
            playback_session_id: playback_session_id,
            tape_id: tape_id,
            began_at: began_at,
            began_ms: ended_ms,
            ended_ms: ended_ms,
            active: 1,
            queued: 0,
          });
        });
    });
  }
}
