import { Controller } from "@hotwired/stimulus";
import { FrameElement } from "@hotwired/turbo/dist/types/elements/frame_element";
import Leaflet, {
  LatLngExpression,
  LeafletKeyboardEventHandlerFn,
} from "leaflet";

import {
  _onMove as _patchedOnMove,
  finishDrag as patchedFinishDrag,
} from "../lib/leaflet_draggable_patch";

// @ts-ignore
Leaflet.Draggable.prototype._onMove = _patchedOnMove;
Leaflet.Draggable.prototype.finishDrag = patchedFinishDrag;

import "leaflet.markercluster";

import Choices from "choices.js";
import { Turbo } from "@hotwired/turbo-rails";
import QueryString from "qs";
import Logger from "./logger";
import { csrfToken } from "../lib/util";

// https://discuss.hotwired.dev/t/stimulus-and-typescript/2458/3
// https://github.com/hotwired/stimulus/pull/529

const ITALY_BOUNDS = new Leaflet.LatLngBounds(
  new Leaflet.LatLng(35.2889616, 6.6272658),
  new Leaflet.LatLng(47.0921462, 18.7844746)
);

const IRAN_BOUNDS = new Leaflet.LatLngBounds(
  new Leaflet.LatLng(24.8353084, 44.0318908),
  new Leaflet.LatLng(39.7824624, 63.3332704)
);

const EUROPE_BOUNDS = new Leaflet.LatLngBounds(
  new Leaflet.LatLng(26, -15),
  new Leaflet.LatLng(76, 35)
);

const MAX_BOUNDS = ITALY_BOUNDS.extend(IRAN_BOUNDS).pad(0.2);

const REFRESH_DEBOUNCE_MS = 250;

interface Search {
  n: number;
  s: number;
  w: number;
  e: number;
  kwd: string;
  lat: number | null;
  lon: number | null;
  r: number;
}

interface Focus {
  value: string;
  label: string;
  customProperties: {
    lat: number;
    lon: number;
    n: number;
    s: number;
    w: number;
    e: number;
  };
}

const CLU_ICON = (n: number) =>
  Leaflet.divIcon({ className: "map-grp", html: `${n}` });
const PIN_ICON = Leaflet.divIcon({ className: "map-pin" });

const YAH_ICON = Leaflet.divIcon({
  className: "map-yah ms",
  html: "&#xe55c;" /* TODO: find workaround w/ new icons */,
});

const TILE_LAYER_OPTIONS: [string, Leaflet.TileLayerOptions] = [
  "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  {
    maxZoom: 19,
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  },
];

const SS_KEY = "LAST_SEARCH";

// TODO: solve double request of first load when navigating to pagw w/o query
export default class extends Controller {
  log = new Logger("Map");

  static targets = [
    "searchContainer",
    "mapContainer",
    "dataFrame",
    "serializedQuery",
    "filtersModal",
    "resultsContainer",
    "resultsCounter",
  ];
  declare readonly searchContainerTarget: HTMLSelectElement;
  declare readonly mapContainerTarget: HTMLDivElement;
  declare readonly dataFrameTarget: FrameElement;
  declare readonly serializedQueryTarget: HTMLScriptElement;
  declare readonly filtersModalTarget: HTMLDivElement;
  declare readonly resultsContainerTarget: HTMLDivElement;
  declare readonly resultsCounterTarget: HTMLDivElement;

  static values = {
    resultsUrl: String,
    gpsAuthErrorMsg: String,
    gpsRandErrorMsg: String,
    unexpectedErrorMsg: String,
  };

  resultsUrlValue!: string;
  gpsAuthErrorMsgValue!: string;
  gpsRandErrorMsgValue!: string;
  unexpectedErrorMsgValue!: string;

  static classes = ["resultsContainerOpen", "resultsContainerDrag"];

  resultsContainerOpenClass!: string;
  resultsContainerDragClass!: string;

  map!: Leaflet.Map;
  markerClusterGroup!: any; // TODO: an actual type
  choices!: Choices;

  refreshDebounceTimeout!: number;
  geolocationDebounceTimeout!: number;

  search!: Search;

  connect() {
    this.loadSearch();

    this.initMap();
    this.createFocusMarkers();
    this.initPlaceSelect();
    this.centerSearchFocus();
    this.fitSearchBounds();
  }

  disconnect() {
    // NOTE: this avoids the lingering background map when backing into the search page
    this.choices.destroy();
    this.map.off();
    this.map.remove();
  }

  initPlaceSelect() {
    this.log.debug("Initializing place search bar");

    // TODO: query guides by name via ajax
    this.choices = new Choices(this.searchContainerTarget, {
      allowHTML: true,
      placeholder: false,
      loadingText: "Loading...",
      noResultsText: "Nessun risultato",
      noChoicesText: "Digita la tua ricerca!",
      itemSelectText: "",
      searchFloor: 3,
      removeItemButton: true,
    });

    this.searchContainerTarget.addEventListener(
      "search",
      async (event: any) => {
        clearTimeout(this.geolocationDebounceTimeout);
        this.geolocationDebounceTimeout = setTimeout(async () => {
          const foci = await this.postPlacesGeolocation(event.detail.value);
          const cFoci = foci.map((focus: any): Focus => {
            return {
              value: focus.place_id,
              label: focus.display_name,
              customProperties: {
                lat: parseFloat(focus.lat),
                lon: parseFloat(focus.lon),
                s: parseFloat(focus.boundingbox[0]),
                n: parseFloat(focus.boundingbox[1]),
                w: parseFloat(focus.boundingbox[2]),
                e: parseFloat(focus.boundingbox[3]),
              },
            };
          });
          this.choices.setChoices(cFoci, "value", "label", true);
        }, 300);
      }
    );

    this.searchContainerTarget.addEventListener(
      "choice",
      async (event: any) => {
        const choice = event.detail.choice as Focus;
        this.search.lat = choice.customProperties.lat!;
        this.search.lon = choice.customProperties.lon!;
        // NOTE: the bounding box is recentered -- not sure why there's an offset sometimes
        this.search.n =
          choice.customProperties.n -
          (choice.customProperties.n * 0.5 + choice.customProperties.s * 0.5) +
          this.search.lat;
        this.search.w =
          choice.customProperties.w -
          (choice.customProperties.w * 0.5 + choice.customProperties.e * 0.5) +
          this.search.lon;
        this.search.s =
          choice.customProperties.s -
          (choice.customProperties.n * 0.5 + choice.customProperties.s * 0.5) +
          this.search.lat;
        this.search.e =
          choice.customProperties.e -
          (choice.customProperties.w * 0.5 + choice.customProperties.e * 0.5) +
          this.search.lon;
        this.addFocusMarkers([this.search.lat, this.search.lon]);
        this.fitSearchBounds();
        this.saveSearchToForm();
      }
    );

    this.searchContainerTarget.addEventListener("removeItem", async () => {
      this.remFocusMarkers();
      this.search.lat = null;
      this.search.lon = null;
      this.debouncedRefresh();
    });
  }

  initMap() {
    if (this.map) return;

    this.map = Leaflet.map(this.mapContainerTarget, {
      center: ITALY_BOUNDS.getCenter(),
      minZoom: 3,
      maxBounds: MAX_BOUNDS,
      maxBoundsViscosity: 0.9,
      zoomControl: false,
    });

    // Leaflet.rectangle(ITALY_BOUNDS).addTo(this.map);
    // Leaflet.rectangle(EUROPE_BOUNDS).addTo(this.map);
    // Leaflet.rectangle(IRAN_BOUNDS).addTo(this.map);
    // Leaflet.rectangle(MAX_BOUNDS).addTo(this.map);

    this.map.on("load", this.refresh.bind(this));
    this.map.on("moveend", () => {
      this.updateSearchBounds();
      this.saveSearchToForm();
      this.debouncedRefresh();
    });

    Leaflet.tileLayer(...TILE_LAYER_OPTIONS).addTo(this.map);

    this.markerClusterGroup = this.createMarkerClusterGroup();
    this.markerClusterGroup.addTo(this.map);
  }

  createMarkerClusterGroup() {
    return (Leaflet as any).markerClusterGroup({
      chunkedLoading: true,
      chunkProgress: (added: number, total: number, elapsed: number) => {
        this.log.debug(`Added ${added}/${total} markers in ${elapsed}ms.`);
      },
      showCoverageOnHover: false,
      maxClusterRadius: (zoom: number) => {
        const [minZ, maxZ] = [3, 19]; // min/max zoom levels
        const [minR, maxR] = [48, 80]; // min/max radii
        const l = (zoom - minZ) / (maxZ - minZ);
        return minR + (maxR - minR) * (1 - l ** 2);
      },
      zoomToBoundsOnClick: true,
      spiderfyOnMaxZoom: true,
      removeOutsideVisibleBounds: true,
      spiderLegPolylineOptions: {
        opacity: 0,
      },
      iconCreateFunction: function (cluster: any) {
        // TODO: improve sum efficiency
        const count = cluster
          .getAllChildMarkers()
          .reduce(
            (partialSum: number, a: any) =>
              partialSum + parseInt(a.options.title || "1"),
            0
          );
        return CLU_ICON(count);
      },
    });
  }

  fitSearchBounds() {
    this.map.fitBounds([
      [this.search.n, this.search.w],
      [this.search.s, this.search.e],
    ]);
  }

  debouncedRefresh() {
    clearTimeout(this.refreshDebounceTimeout);
    this.refreshDebounceTimeout = setTimeout(
      this.refresh.bind(this),
      REFRESH_DEBOUNCE_MS
    );
  }

  async refresh() {
    await this.loadPlacesViaAjax();
    this.map.invalidateSize(); // NOTE: this is the nuclear solution to Safari half-loading the map at first init
    await this.reloadDataFrame();
  }

  async loadPlacesViaAjax() {
    const url = this.serializeSearchForQuery(this.resultsUrlValue).toString();
    await fetch(url, {
      headers: {
        "X-CSRF-Token": csrfToken(),
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) throw response;
        return response;
      })
      .then<
        {
          id: string;
          coord: Leaflet.LatLngLiteral;
        }[]
      >((response) => response.json())
      .then((data) => {
        this.clearMarkers();
        this.resultsCounterTarget.textContent = data.length.toString();
        this.loadPlaces(data);
      })
      .catch(() => alert(this.unexpectedErrorMsgValue));
  }

  updateSearchBounds() {
    const bounds = this.map.getBounds();
    this.search.n = bounds.getNorth();
    this.search.w = bounds.getWest();
    this.search.s = bounds.getSouth();
    this.search.e = bounds.getEast();
  }

  async reloadDataFrame() {
    const newSrc = this.serializeSearchForQuery(
      this.resultsUrlValue
    ).toString();
    if (this.dataFrameTarget.src == newSrc) {
      this.log.debug("dataFrameTarget.src unchanged -> skipping reload");
    } else {
      this.log.debug("dataFrameTarget.src changed -> performing reload");
      this.dataFrameTarget.delegate.disconnect();
      this.dataFrameTarget.removeAttribute("completed");
      // this.dataFrameTarget.replaceChildren();
      (this.dataFrameTarget.delegate as any).hasBeenLoaded = false;
      this.dataFrameTarget.src = newSrc;
      this.dataFrameTarget.delegate.connect();
      // this.dataFrameTarget.reload(); // NOTE: this is unnecessary, in theory
      // await this.dataFrameTarget.loaded;
    }
  }

  async postPlacesGeolocation(query: string) {
    return await fetch("/places/geolocation", {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query }),
    }).then((response) => response.json());
  }

  async postPlacesReverseGeolocation(query: [number, number]) {
    return await fetch("/places/reverse_geolocation", {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query }),
    }).then((response) => response.json());
  }

  clearMarkers() {
    this.markerClusterGroup.clearLayers();
  }

  loadPlaces(
    places: {
      id: string;
      coord: Leaflet.LatLngLiteral;
    }[]
  ) {
    this.log.info("Creating markers");
    const markers = places.map(({ id, coord }) => {
      return Leaflet.marker(coord, { icon: PIN_ICON }).on("click", () =>
        this.focusCard(id)
      );
    });
    this.log.info("Adding markers");
    this.markerClusterGroup.addLayers(markers);
  }

  async focusCard(id: string) {
    this.toggleDrawerOpen(true);

    // NOTE: we need to confirm the data was loaded
    await this.dataFrameTarget.loaded;

    // NOTE: we need to let the height transition happen for this to look good
    setTimeout(() => {
      const card = document.querySelector(`[data-card-id="${id}"]`);
      card?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      (card as HTMLElement).focus();
    }, 150);
  }

  getCurrentPositionCallback: PositionCallback = async (position) => {
    const coords: LatLngExpression = [
      position.coords.latitude,
      position.coords.longitude,
    ];
    const foci = await this.postPlacesReverseGeolocation(coords);
    const focus = {
      selected: true,
      value: foci[0].place_id,
      label: foci[0].display_name,
      customProperties: {
        lat: parseFloat(foci[0].lat),
        lon: parseFloat(foci[0].lon),
        s: parseFloat(foci[0].boundingbox[0]),
        n: parseFloat(foci[0].boundingbox[1]),
        w: parseFloat(foci[0].boundingbox[2]),
        e: parseFloat(foci[0].boundingbox[3]),
      },
    };
    this.choices.setChoices([focus], "value", "label", true);
    this.search.lat = focus.customProperties.lat;
    this.search.lon = focus.customProperties.lon;
    this.search.n = focus.customProperties.n;
    this.search.w = focus.customProperties.w;
    this.search.s = focus.customProperties.s;
    this.search.e = focus.customProperties.e;
    this.addFocusMarkers([this.search.lat!, this.search.lon!]);
    this.fitSearchBounds();
  };

  getCurrentPositionErrorCallback: PositionErrorCallback = ({
    code,
    message,
  }) => {
    // { PERMISSION_DENIED: 1, POSITION_UNAVAILABLE: 2, TIMEOUT: 3 }
    // TODO: browser-based troubleshooting suggestions
    console.error(code, message);
    switch (code) {
      case 1:
        alert(this.gpsAuthErrorMsgValue);
        break;
      default:
        alert(this.gpsRandErrorMsgValue);
        break;
    }
  };

  gps() {
    navigator.geolocation.getCurrentPosition(
      this.getCurrentPositionCallback,
      this.getCurrentPositionErrorCallback
    );
  }

  showFiltersModal() {
    this.filtersModalTarget.classList.remove("hidden");
  }

  hideFiltersModal() {
    this.filtersModalTarget.classList.add("hidden");
  }

  //==[ Search storage ]========================================================

  serializeSearchForQuery(pathName?: string): URL {
    const url = new URL(window.location.toString());

    if (pathName !== undefined) url.pathname = pathName;

    // NOTE: this needs to be coherent with the Rails serialization
    url.search = QueryString.stringify(
      { q: this.search },
      { sort: (a, b) => a.localeCompare(b), arrayFormat: "brackets" }
    );

    return url;
  }

  saveSearchToForm() {
    (document.getElementById("q_lat") as HTMLInputElement).value = (
      this.search.lat || ""
    ).toString();
    (document.getElementById("q_lon") as HTMLInputElement).value = (
      this.search.lon || ""
    ).toString();
    (document.getElementById("q_n") as HTMLInputElement).value =
      this.search.n.toString();
    (document.getElementById("q_w") as HTMLInputElement).value =
      this.search.w.toString();
    (document.getElementById("q_s") as HTMLInputElement).value =
      this.search.s.toString();
    (document.getElementById("q_e") as HTMLInputElement).value =
      this.search.e.toString();
  }

  loadSearch() {
    // NOTE: the only reason we don't use this.dataFrameTarget.src is to preserve types easily
    this.search = JSON.parse(this.serializedQueryTarget.textContent || "");
  }

  //==[ Focus markers ]=========================================================

  focusCenterMarker!: Leaflet.Marker;
  focusRadiusMarker!: Leaflet.Circle;

  createFocusMarkers() {
    this.focusCenterMarker = Leaflet.marker([0, 0], { icon: YAH_ICON });
    this.focusRadiusMarker = Leaflet.circle([0, 0], {
      radius: 1000,
      // TODO: parameterize colors
      color: "#ff0041",
      fillColor: "#ff0041",
      opacity: 0.4,
      fillOpacity: 0.2,
      weight: 2,
    });
  }

  addFocusMarkers(latlng: Leaflet.LatLngExpression, radius = 0) {
    this.focusCenterMarker.setLatLng(latlng).addTo(this.map);
    this.focusRadiusMarker.setLatLng(latlng).setRadius(radius).addTo(this.map);
  }

  remFocusMarkers() {
    this.focusCenterMarker.removeFrom(this.map);
    this.focusRadiusMarker.removeFrom(this.map);
  }

  centerSearchFocus() {
    if (!this.search.lat || !this.search.lon) return;
    const choice = {
      value: "LAST_CHOICE",
      label: `${this.search.lat} ${this.search.lon}`, // TODO: provide actual label
      customProperties: {
        lat: this.search.lat,
        lon: this.search.lon,
        s: this.search.s,
        n: this.search.n,
        w: this.search.w,
        e: this.search.e,
      },
      selected: true,
    };

    this.choices.setValue([choice]);

    this.addFocusMarkers([this.search.lat, this.search.lon], this.search.r);
  }

  //=[ Drawer ]=================================================================

  // FIXME: you can drag the drawer out of bounds and it bounces to opposite state; not sure if bug or feature.

  hi!: number;
  yi!: number;
  yf!: number;
  ti!: number;
  tf!: number;
  drawerStatus = false;

  drawerDragStart(event: TouchEvent) {
    this.toggleDrawerDrag(true);
    const loc = event.targetTouches[0];
    this.ti = event.timeStamp;
    this.yi = loc.clientY;
    this.hi = this.resultsContainerTarget.clientHeight;
  }

  drawerDragMove(event: TouchEvent) {
    const loc = event.targetTouches[0];
    this.yf = loc.clientY;
    this.resultsContainerTarget.style.height = `${
      this.hi + this.yi - loc.clientY
    }px`;
  }

  drawerDragEnd(event: TouchEvent) {
    this.tf = event.timeStamp;
    this.toggleDrawerDrag(false);
    const dy = Math.abs(this.yf - this.yi);
    const dt = this.tf - this.ti;
    // NOTE: dt is just to exclude handling quick taps
    if (dy > window.innerHeight * 0.2 && dt > 50)
      this.toggleDrawerOpen(this.yi > window.innerHeight * 0.5);
    this.resultsContainerTarget.style.removeProperty("height");
  }

  toggleDrawerDrag(force: boolean) {
    this.resultsContainerTarget.classList.toggle(
      this.resultsContainerDragClass,
      force
    );
  }

  toggleDrawerOpen(force: boolean) {
    this.resultsContainerTarget.classList.toggle(
      this.resultsContainerOpenClass,
      force
    );
  }

  drawerTap() {
    this.resultsContainerTarget.classList.toggle(
      this.resultsContainerOpenClass
    );
  }
}
