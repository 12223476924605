import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["barVisible", "transparent", "invisible"];

  barVisibleClass!: string;
  transparentClass!: string;
  invisibleClass!: string;

  static targets = ["bar", "sensor", "hidable", "showable"];

  barTarget!: HTMLElement;
  sensorTarget!: HTMLElement;
  hasSensorTarget!: boolean;
  hidableTargets!: HTMLElement[];
  showableTargets!: HTMLElement[];

  threshold = 0.5;
  transitionDuration = 150; // NOTE: corresponds to tailwind's default value

  connect() {
    if (!this.hasSensorTarget) return;
    this.observer.observe(this.sensorTarget);
  }

  disconnect() {
    // TODO: I believe this is unnecessary thanks to Turbo removing stuff from the page
    if (!this.hasSensorTarget) return;
    this.observer.unobserve(this.sensorTarget);
  }

  callback: IntersectionObserverCallback = ([entry]) => {
    const deployed = entry.intersectionRatio >= this.threshold;
    this.barTarget.classList.toggle(this.barVisibleClass, !deployed);
    this.showableTargets.forEach((target) => {
      this.transitionInvisibility(target, deployed);
      target.classList.toggle(this.transparentClass, deployed);
    });
    this.hidableTargets.forEach((target) => {
      this.transitionInvisibility(target, !deployed);
      target.classList.toggle(this.transparentClass, !deployed);
    });
  };

  options: IntersectionObserverInit = {
    threshold: this.threshold,
  };

  observer = new IntersectionObserver(this.callback, this.options);

  transitionInvisibility(target: HTMLElement, force?: boolean) {
    const oldTimeout = target.getAttribute("data-topbar-timeout");
    if (oldTimeout !== null) clearTimeout(parseInt(oldTimeout));

    const toggleInvisible = () =>
      target.classList.toggle(this.invisibleClass, force);

    if (force) {
      const newTimeout = setTimeout(toggleInvisible, this.transitionDuration);
      target.setAttribute("data-topbar-timeout", newTimeout.toString());
    } else {
      toggleInvisible();
    }
  }
}
