import { Controller } from "@hotwired/stimulus";
import Logger from "./logger";
import { csrfToken } from "../lib/util";

enum DownloadState {
  Initiated = "initiated",
  Completed = "completed",
  Cancelled = "cancelled",
  Condemned = "condemned",
  Dismissed = "dismissed",
}

interface Download {
  id: string;
  tape_id: string;
  state: DownloadState;
  manifest: string[];
}

export default class extends Controller {
  log = new Logger("Downloader");

  static targets = [
    "button",
    "overlay",
    "progress",
    "introMessage",
    "outroMessage",
  ];
  declare readonly buttonTargets: HTMLButtonElement[];
  declare readonly progressTarget: HTMLDivElement;
  declare readonly overlayTarget: HTMLDivElement;
  declare readonly introMessageTarget: HTMLDivElement;
  declare readonly outroMessageTarget: HTMLDivElement;
  declare readonly hasIntroMessageTarget: boolean;
  declare readonly hasOutroMessageTarget: boolean;

  static values = {
    tapeId: String,
    downloadId: String,
    genericErrorMsg: String,
  };
  tapeIdValue!: string;
  downloadIdValue!: string;
  genericErrorMsgValue!: string;

  cache!: Cache;
  download: Download | undefined;

  cur = 0;
  tot = 0;

  async connect() {
    this.cache = await caches.open("offline");
    this.log.debug(`Cache opened`);

    // TODO: what if this fails?
    if (this.downloadIdValue) await this.fetchDownload();

    this.toggleHighlightedButtons(this.download !== undefined);
  }

  async fetchDownload() {
    this.log.debug(`Fetching download`);
    const url = this.downloadPath(this.downloadIdValue);
    this.download = await (await fetch(url))?.json();
    this.log.debug(`Download fetched`);
  }

  async toggle(event: PointerEvent) {
    event.preventDefault();

    this.toggleBusyButtons(true);

    [this.cur, this.tot] = [0, 0];
    this.renderProgress();
    this.showMessage(this.download === undefined ? "intro" : "outro");
    this.fadeInOverlay();

    try {
      if (this.download === undefined) {
        await this.performDownloadCreation();
      } else {
        await this.performDownloadDeletion();
      }
    } catch (error: any) {
      this.log.error(error);
      alert(this.genericErrorMsgValue);
    }

    await fetch(`/downloads`); // optional
    await fetch(`/downloads/offline`); // essential

    this.fadeOutOverlay();

    this.toggleBusyButtons(false);

    this.toggleHighlightedButtons(this.download !== undefined);
  }

  //=[ Download creation/deletion ]=============================================

  async performDownloadCreation() {
    if (this.download) return; // just for safety

    // NOTE: it's just easier to use a railway instead of branching conditions
    try {
      this.log.info("Initiating download");
      const createdDownload = await this.createDownload(this.tapeIdValue);
      if (!createdDownload) throw new Error("Download initialization failed");
      this.download = createdDownload;
      this.log.info("Download initiated");

      this.log.info("Caching manifest assets");
      const cacheSuccess = await this.cacheManifestAssets(
        this.download.manifest
      );
      if (!cacheSuccess) throw new Error("Manifest assets caching failed");
      this.log.info("Manifest assets cached");

      this.log.info("Completing download");
      const updatedDownload = await this.updateDownloadState(
        this.download.id,
        DownloadState.Completed
      );
      if (!updatedDownload) throw new Error("Download completion failed");
      this.download = updatedDownload;
      this.log.info("Download completed");
    } catch (error) {
      // NOTE: this.download should never be undefined in practice at this point
      if (this.download) {
        this.log.info("Flushing manifest assets");
        await this.flushManifestAssets(this.download.manifest);
        this.log.info("Manifest assets flushed");

        this.log.info("Cancelling download");
        const cancelledDownload = await this.updateDownloadState(
          this.download.id,
          DownloadState.Cancelled
        );
        // NOTE: if this fails it's nbd
        if (cancelledDownload) {
          this.log.info("Download completed");
        } else {
          this.log.error("Download cancellation failed");
        }
      }
      this.download = undefined;
      throw error;
    }
  }

  async performDownloadDeletion() {
    if (!this.download) return; // just for safety

    // NOTE: it's just easier to use a railway instead of branching conditions
    try {
      this.log.info("Condemning download");
      const condemnedDownload = await this.updateDownloadState(
        this.download.id,
        DownloadState.Condemned
      );
      if (!condemnedDownload) throw new Error("Download condemnation failed");
      this.download = condemnedDownload;
      this.log.info("Download condemned");

      this.log.info("Flushing manifest assets");
      await this.flushManifestAssets(this.download.manifest);
      this.log.info("Manifest assets flushed");

      this.log.info("Dismissing download");
      const updatedDownload = await this.updateDownloadState(
        this.download.id,
        DownloadState.Dismissed
      );
      if (!updatedDownload) throw new Error("Download dismissal failed");
      this.download = updatedDownload;
      this.log.info("Download dismissed");

      this.download = undefined;
    } catch (error) {
      // NOTE: not much to recover from, I guess
      throw error;
    }
  }

  //=[ Manifest flush/cache helpers ]===========================================

  async cacheManifestAssets(urls: string[]) {
    // TODO: we're leveraging presence of SW, but maybe we could go direct to cache
    var promisedResponses = urls.map((url) => fetch(url, { mode: "cors" }));
    const responses = await Promise.all(promisedResponses);
    for (var response of responses) await this.processResponse(response);
    return responses.every((r) => r.ok);
  }

  async processResponse(response: Response) {
    const reader = response.body?.getReader();
    const contentLength = +(response.headers.get("Content-Length") as string);
    this.tot += contentLength;
    while (true) {
      // await new Promise((r) => setTimeout(r, 50));
      const { done, value } = await reader!.read();
      if (done) {
        this.log.debug("Manifest asset cached:", response.url);
        break;
      }
      this.cur += value.length;
      this.renderProgress();
    }
  }

  async flushManifestAssets(urls: string[]) {
    this.tot = urls.length;
    for (let url of urls) {
      const key = this.removeSearch(url);
      const result = await this.cache.delete(key);
      const humanResult = result
        ? "Manifest asset deleted:"
        : "Manifest asset not found:";
      this.log.debug(humanResult, url);
      this.cur += 1;
      this.renderProgress();
    }
  }

  //=[ API helpers ]============================================================

  tapeDownloadsPath(tapeId: string) {
    return `/tapes/${tapeId}/downloads`;
  }

  downloadPath(downloadId: string) {
    return `/downloads/${downloadId}`;
  }

  async createDownload(tapeId: string) {
    this.log.debug("Creating download");
    return await fetch(this.tapeDownloadsPath(tapeId), {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken(),
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw response;
      })
      .then((response) => {
        this.log.debug("Download created successfully");
        return response as Download;
      })
      .catch((reason) => {
        this.log.error("Download creation failed:", reason);
        return undefined;
      });
  }

  async updateDownloadState(downloadId: string, state: DownloadState) {
    this.log.debug("Updating download state to", state);
    return await fetch(this.downloadPath(downloadId), {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": csrfToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ state }),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw response;
      })
      .then((response) => {
        this.log.debug("Download updated successfully");
        return response as Download;
      })
      .catch((reason) => {
        this.log.error("Download update failed:", reason);
        return undefined;
      });
  }

  //=[ UI helpers ]=============================================================

  toggleBusyButtons(force: boolean) {
    this.buttonTargets.forEach((element) => {
      element.disabled = force;
      element.classList.toggle("spinning", force);
    });
  }

  toggleHighlightedButtons(force: boolean) {
    this.buttonTargets.forEach((element) => {
      element.classList.toggle("btn--toggle-on", force);
      element.classList.toggle("btn--toggle-off", !force);
    });
  }

  fadeInOverlay() {
    this.overlayTarget.classList.toggle("invisible", false);
    this.overlayTarget.classList.toggle("opacity-0", false);
  }

  showMessage(msg: "intro" | "outro") {
    if (this.hasIntroMessageTarget)
      this.introMessageTarget.classList.toggle("hidden", msg != "intro");
    if (this.hasOutroMessageTarget)
      this.outroMessageTarget.classList.toggle("hidden", msg != "outro");
  }

  fadeOutOverlay() {
    this.overlayTarget.classList.toggle("opacity-0", true);
    const toggleInvisible = () =>
      this.overlayTarget.classList.toggle("invisible", true);
    setTimeout(toggleInvisible, 150);
  }

  renderProgress() {
    this.progressTarget.innerHTML = this.formatProgress();
  }

  //=[ Generic helpers ]========================================================

  formatProgress() {
    if (this.tot == 0) return "0%";
    const cur = this.clamp((100 * this.cur) / this.tot, 0, 100);
    return `${cur.toFixed(0)}%`;
  }

  clamp(num: number, min: number, max: number) {
    if (num > max) return max;
    if (num < min) return min;
    return num;
  }

  removeSearch(url: string) {
    const key = new URL(url);
    key.search = "";
    return key.toString();
  }
}
