import { Controller } from "@hotwired/stimulus";
import Logger from "./logger";
import { csrfToken } from "../lib/util";

enum DownloadState {
  Initiated = "initiated",
  Completed = "completed",
  Cancelled = "cancelled",
  Condemned = "condemned",
  Dismissed = "dismissed",
}

interface Download {
  id: string;
  tape_id: string;
  state: DownloadState;
  manifest: string[];
}

export default class extends Controller {
  log = new Logger("Offline");

  static classes = ["cardCompromised"];

  cardCompromisedClass!: string;

  static targets = ["card", "warning"];

  declare readonly cardTargets: HTMLElement[];
  declare readonly warningTarget: HTMLElement;
  declare readonly hasWarningTarget: boolean;

  cache!: Cache;

  cachedKeys!: Set<string>;
  // completedDownloads!: Download[];

  async connect() {
    this.cache = await caches.open("offline");
    this.log.debug(`Cache opened`);

    this.cachedKeys = new Set((await this.cache.keys()).map((r) => r.url));

    await this.flashMissingKeys();
    await this.purgeIllegalKeys();

    // await this.checkMissingKeys();
  }

  purgeIllegalKeys() {
    return this.fetchDownloadsIndex()
      .then((completedDownloads) => {
        const remoteUrls = new Set([
          new URL("/downloads/offline", window.location.origin).toString(),
          ...completedDownloads.flatMap((d) => d.manifest),
        ]);
        const remoteKeys = new Set([...remoteUrls].map((u) => u.split("?")[0]));

        return [...this.cachedKeys].filter((url) => !remoteKeys.has(url));
      })
      .then((illegalKeys) => {
        this.log.log(illegalKeys.length, "cached keys are illegal");
        if (!illegalKeys.length) return;
        this.log.log("Flushing illegal cached keys...");
        return Promise.all(illegalKeys.map((k) => this.cache.delete(k)));
      })
      .catch((reason) => {
        if (reason instanceof TypeError) {
          // NOTE: this is a network error
          window.Rollbar.warning(reason);
          this.log.warn(reason.message);
        } else if (reason instanceof Response && reason.status == 401) {
          this.log.warn(reason.statusText);
        } else {
          window.Rollbar.error(reason);
          this.log.error(reason);
        }
      });
  }

  async flashMissingKeys() {
    let needsWarning = false;
    this.cardTargets.forEach((e) => {
      const manifest: string[] = JSON.parse(e.dataset.downloadManifest || "[]");
      const isFullyCached = manifest.every((url) =>
        this.cachedKeys.has(url.split("?")[0])
      );
      this.log.warn("Cache incomplete for download", e.dataset.downloadId);
      e.classList.toggle(this.cardCompromisedClass, !isFullyCached);
      needsWarning ||= !isFullyCached;
    });
    if (this.hasWarningTarget)
      this.warningTarget.classList.toggle("hidden", !needsWarning);
  }

  // async checkMissingKeys() {
  //   const compromisedDownloadsIds: string[] = [];

  //   this.completedDownloads.forEach((download) => {
  //     const keys = download.manifest.map((u) => u.split("?")[0]);
  //     const missingKeys = keys.filter((key) => !this.cachedKeys.has(key));
  //     if (missingKeys.length) compromisedDownloadsIds.push(download.id);
  //   });

  //   this.log.log(
  //     compromisedDownloadsIds.length,
  //     "downloads are compromised due to missing cache keys"
  //   );

  //   this.cardTargets.forEach((e) => {
  //     const isCompromised = compromisedDownloadsIds.includes(
  //       e.dataset.downloadId!
  //     );
  //     e.classList.toggle(this.cardCompromisedClass, isCompromised);
  //   });
  //   // data-downloader-download-id-value=@download&.id
  // }

  //=[ API helpers ]============================================================

  fetchDownloadsIndex() {
    return fetch("/downloads", {
      headers: {
        "X-CSRF-Token": csrfToken(),
        Accept: "application/json",
      },
    }).then<Download[]>((response) => {
      if (response.ok) return response.json();
      throw response;
    });
  }
}
