import { Controller } from "@hotwired/stimulus";
import {
  GeocoderAutocomplete,
  GeocoderAutocompleteOptions,
} from "@geoapify/geocoder-autocomplete";

class XGeocoderAutocomplete extends GeocoderAutocomplete {
  constructor(input: HTMLInputElement, options?: GeocoderAutocompleteOptions) {
    if (!input.parentElement) return;
    input.parentElement.classList.add("relative");
    super(input.parentElement, API_KEY, {
      ...options,
      skipDetails: true,
      skipIcons: true,
      placeholder: " ",
      debounceDelay: 250,
      lang: "it", // TODO: customize per user locale
      bias: { countrycode: ["it"] },
    });

    this["inputClearButton"].remove();

    this["inputElement"].remove();
    this["inputElement"] = input;

    this["inputElement"].setAttribute(
      "placeholder",
      this["options"].placeholder || "Enter an address here"
    );

    this["inputElement"].addEventListener(
      "input",
      this.onUserInput.bind(this),
      false
    );

    this["inputElement"].addEventListener(
      "keydown",
      this["onUserKeyPress"].bind(this),
      false
    );
  }
}

const API_KEY = "1795de6f7d3e4a4a842a1052e92592f2";

// NOTE: this is stolen from https://www.geoapify.com/address-autocomplete
export default class extends Controller {
  static targets = [
    "street",
    "housenumber",
    "city",
    "postcode",
    "county",
    "state",
    "country",
  ];

  declare readonly streetTarget: HTMLInputElement;
  declare readonly housenumberTarget: HTMLInputElement;
  declare readonly cityTarget: HTMLInputElement;
  declare readonly postcodeTarget: HTMLInputElement;
  declare readonly countyTarget: HTMLInputElement;
  declare readonly stateTarget: HTMLInputElement;
  declare readonly countryTarget: HTMLInputElement;

  streetAutocomplete!: GeocoderAutocomplete;
  cityAutocomplete!: GeocoderAutocomplete;
  stateAutocomplete!: GeocoderAutocomplete;
  countryAutocomplete!: GeocoderAutocomplete;

  connect() {
    this.streetAutocomplete = new XGeocoderAutocomplete(this.streetTarget, {
      allowNonVerifiedHouseNumber: true,
      allowNonVerifiedStreet: true,
    });

    this.cityAutocomplete = new XGeocoderAutocomplete(this.cityTarget, {
      type: "city",
    });

    this.stateAutocomplete = new XGeocoderAutocomplete(this.stateTarget, {
      type: "state",
    });

    this.countryAutocomplete = new XGeocoderAutocomplete(this.countryTarget, {
      type: "country",
    });

    this.streetAutocomplete.on("select", (street) => {
      console.log(street);
      if (!street) return;
      this.streetAutocomplete.setValue(street.properties.street || "");
      if (street.properties.housenumber)
        this.housenumberTarget.value = street.properties.housenumber;
      if (street.properties.postcode)
        this.postcodeTarget.value = street.properties.postcode;
      if (street.properties.city)
        this.cityAutocomplete.setValue(street.properties.city);
      if (street.properties.county)
        this.countyTarget.value = street.properties.county;
      if (street.properties.state)
        this.stateAutocomplete.setValue(street.properties.state);
      if (street.properties.country)
        this.countryAutocomplete.setValue(street.properties.country);
    });

    this.cityAutocomplete.on("select", (city) => {
      if (!city) return;
      this.cityAutocomplete.setValue(city.properties.city || "");
      if (city.properties.postcode)
        this.postcodeTarget.value = city.properties.postcode;
      if (city.properties.county)
        this.countyTarget.value = city.properties.county;
      if (city.properties.state)
        this.stateAutocomplete.setValue(city.properties.state);
      if (city.properties.country)
        this.countryAutocomplete.setValue(city.properties.country);
    });

    this.stateAutocomplete.on("select", (state) => {
      if (!state) return;
      this.stateAutocomplete.setValue(state.properties.state || "");
      if (state.properties.country)
        this.countryAutocomplete.setValue(state.properties.country);
    });
  }

  disconnect() {}
}
