//=[ Stimulus application ]=====================================================

import { Application } from "@hotwired/stimulus";
import Rollbar from "rollbar";

declare global {
  interface Window {
    Stimulus: Application;
    Rollbar: Rollbar;
  }
}

const application = Application.start();

declare var process: { env: { NODE_ENV: string } };
application.debug = process.env.NODE_ENV == "development";

import CookiesController from "./controllers/cookies_controller";
import AddressController from "./controllers/address_controller";
import TrackerController from "./controllers/tracker_controller";
import PlayerController from "./controllers/player_controller";
import MapController from "./controllers/map_controller";
import ChoicesController from "./controllers/choices_controller";
import BackController from "./controllers/back_controller";
import DownloaderController from "./controllers/downloader_controller";
import OfflineController from "./controllers/offline_controller";
import WelcomeController from "./controllers/welcome_controller";
import TopbarController from "./controllers/topbar_controller";
import TogglerController from "./controllers/toggler_controller";
import PaletteController from "./controllers/palette_controller";
import ShimsController from "./controllers/shims_controller";

application.register("cookies", CookiesController);
application.register("address", AddressController);
application.register("tracker", TrackerController);
application.register("player", PlayerController);
application.register("map", MapController);
application.register("choices", ChoicesController);
application.register("back", BackController);
application.register("downloader", DownloaderController);
application.register("offline", OfflineController);
application.register("welcome", WelcomeController);
application.register("topbar", TopbarController);
application.register("toggler", TogglerController);
application.register("palette", PaletteController);
application.register("shims", ShimsController);

window.Stimulus = application;

//=[ Turbo and Workbox ]========================================================

import { Turbo } from "@hotwired/turbo-rails";
import { Workbox } from "workbox-window";

declare global {
  interface Window {
    // Turbo: Turbo; // of course it already does...
    Workbox: Workbox;
  }
}

Turbo.setProgressBarDelay(0);

// NOTE: workbox must already be controlling the page before starting turbo, otherwise the latter intercepts requests until a navigation event (which turbo would mostly prevent). Damn, this insight was costly.
(async () => {
  (Turbo as any).session.stop();

  if ("serviceWorker" in (navigator as any)) {
    const wb = new Workbox("/service-worker.js");
    window.Workbox = wb;
    await wb.register();
    await wb.controlling;
  } else {
    // if (navigator.language || (navigator as any).userLanguage == "it") {
    //   window.alert(
    //     "ATTENZIONE: questo browser non supporta l'ascolto offline.\n\nApri AudioGiro in un browser aggiornato per usare tutte le sue funzionalità."
    //   );
    // } else {
    //   window.alert(
    //     "WARNING: this browser does not support offline listening.\n\nOpen AudioGiro in an updated browser to use its full functionality."
    //   );
    // }
    window.Rollbar.warning("Unable to register ServiceWorker");
    // TODO: track this with ahoy
  }

  (Turbo as any).session.start();
})();

import ScrollKeeper from "./components/scroll_keeper";
new ScrollKeeper().start();

// (() => {
//   addEventListener("turbo:load", (e) => {
//     console.log("AG::EVENT", e);
//   });
// })();
