import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";
import Logger from "./logger";
import { csrfToken } from "../lib/util";

declare global {
  // NOTE: goddamn, this was tricky
  interface HTMLElementEventMap {
    search: CustomEvent<{ value: string; resultCount: number }>;
  }
}

export default class extends Controller {
  log = new Logger("Choices");

  static targets = ["select"];
  declare readonly selectTargets: HTMLSelectElement[];

  static values = {
    remote: String,
    options: Object,
  };
  remoteValue!: string;
  optionsValue!: object;

  choicesInstances: Choices[] = [];

  connect() {
    this.selectTargets.forEach(this.initSelect.bind(this));
  }

  disconnect() {
    while (this.choicesInstances.length) {
      let choices = this.choicesInstances.pop()!;
      this.safeChoicesDestoy(choices);
    }
  }

  initSelect(element: HTMLSelectElement) {
    const choices = new Choices(element, this.optionsValue);
    if (this.remoteValue) {
      element.addEventListener("search", async (searchEvent) => {
        const results = await fetch(this.remoteValue, {
          method: "POST",
          headers: {
            "X-CSRF-Token": csrfToken(),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ q: searchEvent.detail.value }),
        }).then((r) => r.json());
        choices.setChoices(results, "value", "label", true);
      });
    }
    this.choicesInstances.push(choices);
  }

  safeChoicesDestoy(choices: Choices) {
    // NOTE: https://github.com/Choices-js/Choices/issues/1088
    let element = choices.passedElement.element;
    if (element instanceof HTMLSelectElement) {
      // NOTE: we iterate on options so both single and multiple selects are covered
      let selectedOptionsValues = Array.from(element.options)
        .filter((option) => option.selected)
        .map((option) => option.value);
      choices.destroy();
      Array.from(element.options).forEach((option) => {
        option.selected = selectedOptionsValues.includes(option.value);
      });
      // } else {
      //   let inputValue = element.value;
      //   choices.destroy();
      //   element.value = inputValue;
    }
  }
}
