import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import Choices from "choices.js";
import Swiper from "swiper";
import { Pagination } from "swiper/modules";

export default class extends Controller {
  static targets = ["lang", "tour", "toggleable"];
  declare readonly hasLangTarget: boolean;
  declare readonly hasTourTarget: boolean;
  declare readonly langTarget: HTMLSelectElement;
  declare readonly tourTarget: HTMLDivElement;
  declare readonly toggleableTargets: HTMLDivElement[];

  swiper!: Swiper;

  connect() {
    if (this.hasTourTarget) {
      this.swiper = new Swiper(this.tourTarget, {
        modules: [Pagination],
        direction: "horizontal",
        loop: false,
        pagination: {
          el: ".swiper-pagination",
        },
      });
      this.swiper.on("slideChange", (swiper: Swiper) => {
        this.toggleableTargets.forEach((e) => {
          const slides: number[] = JSON.parse(e.dataset.slides || "[]");
          e.classList.toggle("hidden", !slides.includes(swiper.activeIndex));
        });
      });
    }

    if (this.hasLangTarget) {
      // NOTE: Choices.js is used here just to present the cute flags
      new Choices(this.langTarget, {
        searchEnabled: false,
        allowHTML: true,
        itemSelectText: "",
        callbackOnCreateTemplates: function (template) {
          return {
            item: (templateOptions: any, item: any, removeItemButton: any) => {
              const labelWithFlag = `<span class="flag ${item.value}"></span><span>${item.label}</span>`;
              return Choices.defaults.templates.item.call(
                this,
                templateOptions,
                Object.assign({}, item, { label: labelWithFlag }),
                removeItemButton
              );
            },
            choice: (templateOptions: any, choice: any, selectText: any) => {
              const labelWithFlag = `<span class="flag ${choice.value}"></span><span>${choice.label}</span>`;
              return Choices.defaults.templates.choice.call(
                this,
                templateOptions,
                Object.assign({}, choice, { label: labelWithFlag }),
                selectText
              );
            },
          };
        },
      });
      // NOTE: we bind to the underlying select so it works without Choices.js
      this.langTarget.addEventListener("change", (e: Event) => {
        const value = (e.target as HTMLSelectElement).value;
        (Turbo as any).visit(`?locale=${value}`);
      });
    }
  }

  prev() {
    this.swiper.slidePrev();
  }

  next() {
    this.swiper.slideNext();
  }
}
