import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  connect() {}

  go({ params: { fallback } }: { params: { fallback: string } }) {
    if (history.length > 1) {
      history.back();
    } else {
      // NOTE: we're replacing to avoid loops
      // TODO: get TS definitions, somehow
      (Turbo as any).visit(fallback, { action: "replace" });
    }
  }
}
