import Logger from "../controllers/logger";

export default class {
  log = new Logger("ScrollKeeper");

  positions: Record<string, number> = {};

  start() {
    this.log.info("Started");

    this.bindEvents();
  }

  targets() {
    return document.querySelectorAll<HTMLElement>("[data-scroll-keeper-id]");
  }

  bindEvents() {
    addEventListener("turbo:before-render", this.saveAll.bind(this));
    addEventListener("turbo:render", this.loadAll.bind(this));
  }

  saveOne = (t: HTMLElement) =>
    (this.positions[t.dataset.scrollKeeperId!] = t.scrollTop);

  loadOne = (t: HTMLElement) =>
    (t.scrollTop = this.positions[t.dataset.scrollKeeperId!]);

  saveAll = () => this.targets().forEach(this.saveOne.bind(this));
  loadAll = () => this.targets().forEach(this.loadOne.bind(this));
}
