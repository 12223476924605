import { Controller } from "@hotwired/stimulus";
import Logger from "./logger";

import "vanilla-cookieconsent";
import { csrfToken } from "../lib/util";

export default class extends Controller {
  static values = {
    languages: Object,
    currentLang: String,
    url: String,
  };

  languagesValue!: Record<string, LanguageSetting>;
  currentLangValue!: string;
  urlValue!: string;

  cookieConsent!: CookieConsent;

  connect() {
    this.cookieConsent = initCookieConsent();

    this.cookieConsent.run({
      autorun: true,
      languages: this.languagesValue,
      current_lang: this.currentLangValue,
      cookie_name: "ag_cookies",
      onAccept: this.sendToServer.bind(this),
      onChange: this.sendToServer.bind(this),
    });
  }

  async sendToServer(savedCookieContent: SavedCookieContent) {
    return await fetch(this.urlValue, {
      method: "PUT",
      headers: {
        "X-CSRF-Token": csrfToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: { cookie_consent_data: savedCookieContent },
      }),
    });
  }

  disconnect() {
    this.cookieConsent.hide();
  }
}
