const enum LogLevels {
  LOG = "log",
  DEBUG = "debug",
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

const TAG_STYLE = [
  "background-color:#ff0041;",
  "font-weight: bold;",
  "border-radius: 0.5em;",
  "padding: 2px 0.5em;",
  "color: white;",
].join("");

declare var process: { env: { NODE_ENV: string } };

export default class Logger {
  tagFragments: string[];

  constructor(tagLabel: string) {
    if (process.env.NODE_ENV == "development") {
      this.tagFragments = [`%cAG::${tagLabel}%c`, TAG_STYLE, ""];
    } else {
      // NOTE: the coloured style would be quite annoying in rollbar
      this.tagFragments = [`[AG::${tagLabel}]`];
    };
  }

  call(method: LogLevels, data: any[]) {
    console[method](...this.tagFragments, ...data);
  }

  log = (...data: any[]) => this.call(LogLevels.LOG, data);
  debug = (...data: any[]) => this.call(LogLevels.DEBUG, data);
  info = (...data: any[]) => this.call(LogLevels.INFO, data);
  warn = (...data: any[]) => this.call(LogLevels.WARN, data);
  error = (...data: any[]) => this.call(LogLevels.ERROR, data);
}
