import { Point, DomEvent, DomUtil, Draggable } from "leaflet";

// NOTE: this patch solves https://github.com/Leaflet/Leaflet/issues/2164 by avoiding changing che class of body

export function _onMove(e) {
  console.log("OVERRIDE1");
  // Ignore the event if disabled; this happens in IE11
  // under some circumstances, see #3666.
  if (!this._enabled) {
    return;
  }

  if (e.touches && e.touches.length > 1) {
    this._moved = true;
    return;
  }

  const first = e.touches && e.touches.length === 1 ? e.touches[0] : e,
    offset = new Point(first.clientX, first.clientY)._subtract(
      this._startPoint
    );

  if (!offset.x && !offset.y) {
    return;
  }
  if (Math.abs(offset.x) + Math.abs(offset.y) < this.options.clickTolerance) {
    return;
  }

  // We assume that the parent container's position, border and scale do not change for the duration of the drag.
  // Therefore there is no need to account for the position and border (they are eliminated by the subtraction)
  // and we can use the cached value for the scale.
  offset.x /= this._parentScale.x;
  offset.y /= this._parentScale.y;

  DomEvent.preventDefault(e);

  if (!this._moved) {
    // @event dragstart: Event
    // Fired when a drag starts
    this.fire("dragstart");

    this._moved = true;

    // NOTE: this comment is the override
    // document.body.classList.add("leaflet-dragging");

    this._lastTarget = e.target || e.srcElement;
    // IE and Edge do not give the <use> element, so fetch it
    // if necessary
    if (
      window.SVGElementInstance &&
      this._lastTarget instanceof window.SVGElementInstance
    ) {
      this._lastTarget = this._lastTarget.correspondingUseElement;
    }
    this._lastTarget.classList.add("leaflet-drag-target");
  }

  this._newPos = this._startPos.add(offset);
  this._moving = true;

  this._lastEvent = e;
  this._updatePosition();
}

export function finishDrag(noInertia) {
  // NOTE: this comment is the override
  // document.body.classList.remove("leaflet-dragging");

  if (this._lastTarget) {
    this._lastTarget.classList.remove("leaflet-drag-target");
    this._lastTarget = null;
  }

  DomEvent.off(document, "mousemove touchmove", this._onMove, this);
  DomEvent.off(document, "mouseup touchend touchcancel", this._onUp, this);

  DomUtil.enableImageDrag();
  DomUtil.enableTextSelection();

  const fireDragend = this._moved && this._moving;

  this._moving = false;
  Draggable._dragging = false;

  if (fireDragend) {
    // @event dragend: DragEndEvent
    // Fired when the drag ends.
    this.fire("dragend", {
      noInertia,
      distance: this._newPos.distanceTo(this._startPos),
    });
  }
}
