import { Controller } from "@hotwired/stimulus";
import Logger from "./logger";

export default class extends Controller {
  log = new Logger("Shims");
  static targets = ["audio"];

  audioTargets!: HTMLAudioElement[];

  connect() {
    this.rerenderAudioOnDesktopSafari();
  }

  rerenderAudioOnDesktopSafari() {
    // https://github.com/hotwired/turbo/issues/892
    // Safari is such a weird little fucking goblin.
    if ((window as any).safari === undefined) return;
    this.log.info(
      `Safari desktop detected; rerendering ${this.audioTargets.length} audio elements.`
    );
    this.audioTargets.forEach((element) => {
      const clone = element.cloneNode(true);
      element.parentNode!.insertBefore(clone, element);
      element.remove();
    });
  }

  disconnect() {}
}
