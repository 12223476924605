import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import Logger from "./logger";

import * as uuid from "uuid";

import { TrackingEventDetail } from "./tracker_controller";

interface JSendSuccess<T> {
  status: "success";
  data: T;
}

// const PLAYBACK_REPORTING_RATE = 5000;

const PLAYBACK_TRACKING_EVENTS = [
  "ended",
  "pause",
  "play",
  "playing",
  "seeked",
  "seeking",
  "timeupdate",
];

export default class extends Controller {
  log = new Logger("Player");

  static targets = [
    "audio",
    "backdrop",
    "barCanvas",
    "barContainer",
    "barLHTime",
    "barRHTime",
    "barSlider",
    "btnAutonext",
    "btnBrowse",
    "btnBwd",
    "btnCue",
    "btnFwd",
    "btnMain",
    "btnNxt",
    "btnPrv",
    "curTitle",
    "recommendedModal",
  ];

  audioTarget!: HTMLAudioElement;
  backdropTarget!: HTMLDivElement;
  barCanvasTarget!: HTMLCanvasElement;
  barContainerTarget!: HTMLDivElement;
  barLHTimeTarget!: HTMLDivElement;
  barRHTimeTarget!: HTMLDivElement;
  barSliderTarget!: HTMLDivElement;
  btnAutonextTarget!: HTMLInputElement;
  btnBrowseTarget!: HTMLInputElement;
  btnBwdTarget!: HTMLButtonElement;
  btnCueTargets!: HTMLButtonElement[];
  btnFwdTarget!: HTMLButtonElement;
  btnMainTarget!: HTMLInputElement;
  btnNxtTarget!: HTMLButtonElement;
  btnPrvTarget!: HTMLButtonElement;
  curTitleTarget!: HTMLDivElement;
  recommendedModalTarget!: HTMLDivElement;

  static values = {
    browsable: Boolean,
    tapeId: String,
    src: String,
    unexpectedErrorMsg: String,
  };

  browsableValue!: boolean;
  tapeIdValue!: string;
  srcValue!: string;
  unexpectedErrorMsgValue!: String;

  static classes = ["browsing", "disabled", "btnCuePlaying"];

  browsingClass!: string;
  disabledClass!: string;
  btnCuePlayingClass!: string;

  lastUserSeek = 0;
  autonext = false;
  browsing = false;
  textTrack!: TextTrack;
  barRangeStart!: number;
  barRangeEnd!: number;

  playbackSessionId!: string;

  currentPlaybackEventId!: string;

  async connect() {
    this.startCreditChecking();

    this.currentPlaybackEventId = this.randomUUID();

    // NOTE: tracking/reporting listens for ended, pause, play, playing, seeked, seeking and timeupdate.
    this.bindPlaybackTrackingEvents();

    this.audioTarget.addEventListener(
      "timeupdate",
      this.onTimeUpdate.bind(this)
    );
    this.audioTarget.addEventListener(
      "loadedmetadata",
      this.onLoadedMetadata.bind(this)
    );
    this.audioTarget.addEventListener(
      "canplay",
      this.setStartingTime.bind(this),
      { once: true }
    );
    this.audioTarget.addEventListener("error", this.onError.bind(this));

    this.log.info("Setting source.");
    // NOTE: src is empty by default
    // NOTE: the autoplay+pause trick should guarantee that canplay triggers on safari
    this.audioTarget.autoplay = true;
    this.audioTarget.src = this.srcValue;
    this.audioTarget.pause();

    this.bindBar();
    this.bindButtons();
    if (this.browsableValue) this.bindBrowsability();

    this.playbackSessionId = this.randomUUID();
  }

  disconnect() {
    this.audioTarget.autoplay = false;
    this.audioTarget.pause(); // TODO: is this fast enough to let the tracking event arrive?
    this.stopCreditChecking();
  }

  setStartingTime() {
    this.log.info("Event received: canplay");
    const t = parseFloat(
      new URLSearchParams(window.location.search).get("t") || ""
    );
    if (isNaN(t) || t === 0) return;
    this.audioTarget.currentTime = t;
    this.log.info("Start time from query string set.");
  }

  onError(event: Event) {
    this.element.classList.toggle(this.disabledClass, true);
    this.log.error((event.target as HTMLAudioElement).error);
    alert(
      "There was an unexpected error loading the media. We're sorry for the inconvenience. Please reload the page and try again."
    );
  }

  //=[ Audio proxy methods ]====================================================

  togglePlaying() {
    if (this.audioTarget.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  play() {
    this.lastUserSeek = Date.now();
    this.audioTarget.play();
  }

  pause() {
    this.lastUserSeek = Date.now();
    this.audioTarget.pause();
  }

  seek(time: number) {
    this.lastUserSeek = Date.now();
    this.audioTarget.currentTime = time;
  }

  skip(timeDelta: number) {
    this.lastUserSeek = Date.now();
    this.audioTarget.currentTime += timeDelta;
  }

  //=[ Control buttons ]========================================================

  bindButtons() {
    this.btnPrvTarget.addEventListener("click", this.onBtnPrv.bind(this));
    this.btnBwdTarget.addEventListener("click", this.onBtnBwd.bind(this));
    this.btnMainTarget.addEventListener("click", this.onBtnMain.bind(this));
    this.btnFwdTarget.addEventListener("click", this.onBtnFwd.bind(this));
    this.btnNxtTarget.addEventListener("click", this.onBtnNxt.bind(this));
    this.btnBrowseTarget.addEventListener("click", this.onBtnBrowse.bind(this));
    this.btnAutonextTarget.addEventListener(
      "click",
      this.onBtnAutonext.bind(this)
    );
  }

  redrawBtnMain() {
    this.btnMainTarget.checked = !this.audioTarget.paused;
  }

  onBtnMain = () => this.togglePlaying();
  onBtnBwd = () => this.skip(-10);
  onBtnFwd = () => this.skip(+10);
  onBtnPrv = () => this.skipcue(-1);
  onBtnNxt = () => this.skipcue(+1);

  //=[ Progress bar ]===========================================================

  bindBar() {
    this.barContainerTarget.addEventListener("click", (event) => {
      const rect = this.barCanvasTarget.getBoundingClientRect();
      const t = (event.clientX - rect.left) / rect.width;
      const time =
        (this.barRangeEnd - this.barRangeStart) * t + this.barRangeStart;
      this.audioTarget.currentTime = time;
      this.seek(this.fractionToTime(t));
    });
    this.barSliderTarget.addEventListener(
      "dragstart",
      this.onSliderDrag.bind(this)
    );
    this.barSliderTarget.addEventListener("drag", this.onSliderDrag.bind(this));
    this.barSliderTarget.addEventListener(
      "dragend",
      this.onSliderDrag.bind(this)
    );
    this.barSliderTarget.addEventListener(
      "touchstart",
      this.onSliderTouch.bind(this)
    );
    this.barSliderTarget.addEventListener(
      "touchmove",
      this.onSliderTouch.bind(this)
    );
    this.barSliderTarget.addEventListener(
      "touchend",
      this.onSliderTouch.bind(this)
    );
  }

  onSliderDrag(event: DragEvent) {
    if (event.type === "dragstart") this.pause();

    if (event.type === "drag" && event.clientX !== 0) {
      const x = event.clientX;
      const rect = this.barCanvasTarget.getBoundingClientRect();
      const t = this.clamp((x - rect.left) / rect.width, 0, 1);
      this.barSliderTarget.style.left = `${rect.width * t}px`;
      this.seek(this.fractionToTime(t));
    }

    if (event.type === "dragend") this.play();
  }

  onSliderTouch(event: TouchEvent) {
    if (event.type === "touchstart") this.pause();

    if (event.type === "touchmove" && event.targetTouches.length > 0) {
      const x = event.targetTouches[0].clientX;
      const rect = this.barCanvasTarget.getBoundingClientRect();
      const t = this.clamp((x - rect.left) / rect.width, 0, 1);
      this.barSliderTarget.style.left = `${rect.width * t}px`;
      this.seek(this.fractionToTime(t));
    }

    if (event.type === "touchend") this.play();
  }

  setSliderPosition(time: number) {
    const rect = this.barCanvasTarget.getBoundingClientRect();
    const left = this.timeToFraction(time) * rect.width;
    this.barSliderTarget.style.left = `${left}px`;
  }

  onTimeUpdate() {
    this.redrawBtnMain();
    this.clearCanvas();
    this.redrawBar();
    this.setSliderPosition(this.audioTarget.currentTime);
    this.drawTimeRanges(this.audioTarget.buffered, "#BBBBBB");
    this.drawTimeRanges(this.audioTarget.seekable, "#DDDDDD");
    this.drawTimeRanges(this.audioTarget.played, "#ff0041");
  }

  onLoadedMetadata() {
    this.log.info("Event received: loadedmetadata");
    this.setRange(0, this.audioTarget.duration);
  }

  //=[ Playback tracker: native ]===============================================

  nativePlayed() {
    const played = [];
    for (let i = 0; i < this.audioTarget.played.length; i++) {
      played.push([
        this.audioTarget.played.start(i),
        this.audioTarget.played.end(i),
      ]);
    }
    return played;
  }

  nativeDuration() {
    return this.audioTarget.duration;
  }

  nativeListened() {
    const audio = this.audioTarget;
    let playedDuration = 0;
    for (let i = 0; i < audio.played.length; i++) {
      playedDuration += audio.played.end(i) - audio.played.start(i);
    }
    return playedDuration;
  }

  //=[ Playback tracker: custom ]===============================================

  bindPlaybackTrackingEvents() {
    PLAYBACK_TRACKING_EVENTS.forEach((eventName) => {
      this.audioTarget.addEventListener(
        eventName,
        this.onPlaybackTrackingEvent.bind(this)
      );
    });
  }

  onPlaybackTrackingEvent(event: Event) {
    const began_at = new Date();
    const ended_ms = Math.round(this.audioTarget.currentTime * 1000);

    if (this.audioTarget.seeking && !event.type.match(/^seek(ed|ing)$/)) {
      // NOTE: this is essential, as it easily leads to negative length playback events
      this.log.debug(`Skipping event ${event.type} while seeking`);
      return;
    }

    this.log.debug(`Handling event ${event.type}`);

    switch (event.type) {
      case "playing":
      case "seeked":
      case "seeking":
        this.currentPlaybackEventId = this.randomUUID();
        this.dispatchTrackingEvent(
          this.currentPlaybackEventId,
          began_at,
          ended_ms,
          1
        );
        break;
      case "timeupdate":
        this.dispatchTrackingEvent(
          this.currentPlaybackEventId,
          began_at,
          ended_ms,
          1
        );
        break;
      case "pause":
      case "ended":
        this.dispatchTrackingEvent(
          this.currentPlaybackEventId,
          began_at,
          ended_ms,
          0
        );
        this.currentPlaybackEventId = this.randomUUID();
        break;
      default:
        this.log.warn(`Event ${event.type} has no handler`);
    }
  }

  async dispatchTrackingEvent(
    id: string,
    began_at: Date,
    ended_ms: number,
    active: 0 | 1
  ) {
    this.log.debug("Dispatching trackingEvent");
    const detail: TrackingEventDetail = {
      id,
      began_at,
      ended_ms,
      active,
      playback_session_id: this.playbackSessionId,
      tape_id: this.tapeIdValue,
    };
    this.dispatch("trackingEvent", { detail });
  }

  // calculateListened() {
  //   const ranges = this.playbackEvents
  //     .filter(({ began_ms, ended_ms }) => ended_ms - began_ms != 0)
  //     .map(
  //       ({ began_ms, ended_ms }) => [began_ms, ended_ms] as [number, number]
  //     ); // duplicate intervals
  //   const mergedRanges = this.mergeIntervalsInPlace(ranges);
  //   return this.sumIntervalsLengths(mergedRanges);
  // }

  // sumIntervalsLengths(intervals: [number, number][]) {
  //   return intervals.map(([s, e]) => e - s).reduce((a, b) => a + b, 0);
  // }

  // mergeIntervalsInPlace(intervals: [number, number][]) {
  //   const result = intervals.sort((a, b) => a[0] - b[0]);
  //   // NOTE: we're skipping the first one
  //   let index = 0;
  //   for (let i = 1; i < result.length; i++) {
  //     if (result[index][1] < result[i][0]) {
  //       index++;
  //       result[index] = result[i];
  //     } else {
  //       result[index][1] = Math.max(result[index][1], result[i][1]);
  //     }
  //   }
  //   return result.slice(0, index + 1);
  // }

  //=[ Browser ]================================================================

  onBtnBrowse = () => this.toggleBrowsing();
  onBtnAutonext = () => this.toggleAutonext();

  toggleAutonext() {
    this.autonext = !this.autonext;
  }

  toggleBrowsing() {
    this.browsing = !this.browsing;
    this.element.classList.toggle(this.browsingClass, this.browsing);
  }

  seekCue({ params: { cueIndex } }: { params: { cueIndex: number } }) {
    const time = this.textTrack.cues![cueIndex].startTime;
    this.seek(time);
  }

  skipcue(delta: number) {
    const activeCues = this.textTrack.activeCues;
    if (activeCues === null || activeCues.length < 1) return;
    const nextCueIndex = Math.max(
      0,
      Math.min(
        this.textTrack.cues!.length - 1,
        parseInt(activeCues[0].id) + delta
      )
    );
    this.seekCue({ params: { cueIndex: nextCueIndex } });
  }

  bindBrowsability() {
    this.textTrack = this.audioTarget.textTracks[0];
    this.btnNxtTarget.disabled = false;
    this.btnPrvTarget.disabled = false;
    this.btnAutonextTarget.disabled = false;
    this.btnBrowseTarget.disabled = false;
    this.textTrack.addEventListener("cuechange", this.onCueChange.bind(this));
  }

  autonextCheck() {
    // TODO: isn't there a better way than an arbitrary 100m threshold?
    const delta = -this.lastUserSeek + (this.lastUserSeek = Date.now());
    if (!this.autonext && delta > 100) this.audioTarget.pause();
  }

  onCueChange(event: Event) {
    this.autonextCheck();
    const activeCues = this.textTrack.activeCues;
    if (!activeCues) return;
    const activeCue = activeCues[0] as VTTCue;
    const metadata = JSON.parse(activeCue.text);
    const pictureUrl = metadata.picture_url;
    this.setBackdropImage(pictureUrl);
    this.setRange(activeCue.startTime, activeCue.endTime);
    this.setHighlightedBtnCue(parseInt(activeCue.id));
    this.curTitleTarget.textContent = metadata.name;
    document.title = metadata.name;
  }

  setHighlightedBtnCue(index: number) {
    this.btnCueTargets.forEach((btn, i) => {
      if (i == index) {
        btn.classList.add(this.btnCuePlayingClass);
      } else {
        btn.classList.remove(this.btnCuePlayingClass);
      }
    });
  }

  setRange(startTime: number, endTime: number) {
    this.barRangeStart = startTime;
    this.barRangeEnd = endTime;
    this.redrawBar();
  }

  //=[ Backdrop ]===============================================================

  setBackdropImage(url: string) {
    this.backdropTarget.style.backgroundImage = `url(${url})`;
  }

  //=[ Progress bar ]===========================================================

  redrawBar() {
    this.barLHTimeTarget.textContent = this.secondsToTimestamp(
      this.audioTarget.currentTime - this.barRangeStart
    );
    this.barRHTimeTarget.textContent =
      "- " +
      this.secondsToTimestamp(this.barRangeEnd - this.audioTarget.currentTime);
  }

  clearCanvas() {
    const canvas = this.barCanvasTarget;
    const context = canvas.getContext("2d");
    if (context === null) return;
    context.fillStyle = "#F1F1F1";
    context.fillRect(0, 0, canvas.width, canvas.height);
  }

  drawTimeRanges(timeRanges: TimeRanges, color: string) {
    const canvas = this.barCanvasTarget;
    const context = canvas.getContext("2d");
    if (context === null) return;
    context.fillStyle = color;
    const tw = this.barRangeEnd - this.barRangeStart;
    for (let i = 0; i < timeRanges.length; i++) {
      if (timeRanges.end(i) <= this.barRangeStart) continue;
      if (this.barRangeEnd <= timeRanges.start(i)) continue;
      const ti = Math.max(this.barRangeStart, timeRanges.start(i));
      const tf = Math.min(this.barRangeEnd, timeRanges.end(i));
      const x = ((ti - this.barRangeStart) / tw) * canvas.width;
      const w = ((tf - ti) / tw) * canvas.width;
      context.fillRect(x, 0, w, canvas.height);
    }
  }

  //=[ Helpers ]================================================================

  fractionToTime(f: number) {
    const dt = this.barRangeEnd - this.barRangeStart;
    return dt * f + this.barRangeStart;
  }

  timeToFraction(t: number) {
    const dt = this.barRangeEnd - this.barRangeStart;
    return (t - this.barRangeStart) / dt;
  }

  clamp(num: number, min: number, max: number) {
    if (num > max) return max;
    if (num < min) return min;
    return num;
  }

  secondsToTimestamp(seconds: number) {
    const m = Math.floor(seconds / 60).toFixed(0);
    const s = (seconds % 60).toFixed().toString().padStart(2, "0");
    return `${m}:${s}`;
  }

  // formatPlayedRanges() {
  //   const audio = this.audioTarget;
  //   let playedRanges = "";
  //   for (let i = 0; i < audio.played.length; i++) {
  //     playedRanges += `${audio.played.start(i)}\t${audio.played.end(i)}\n`;
  //   }
  //   return playedRanges;
  // }

  //=[ Credit checking ]========================================================

  // TODO: use a websocket, please. Also, this is really tacked-on.

  creditCheckingStatus = true;
  creditCheckingRateMs = 10 * 1e3;
  creditCheckingTimeout: number | null = null;

  startCreditChecking() {
    this.performCreditChecking();
    this.creditCheckingTimeout = setInterval(
      this.performCreditChecking.bind(this),
      this.creditCheckingRateMs
    );
  }

  async stopCreditChecking() {
    if (this.creditCheckingTimeout === null) return;
    clearInterval(this.creditCheckingTimeout);
    await this.performCreditChecking();
  }

  async performCreditChecking() {
    if (!navigator.onLine) return;
    await fetch(`/tapes/${this.tapeIdValue}/can_play`)
      .then((response) => {
        if (!response.ok) throw response;
        return response;
      })
      .then((response) => response.json())
      .then((response: JSendSuccess<{ can_play: boolean }>) => {
        if (!response.data.can_play) {
          this.audioTarget.pause();
          (Turbo as any).visit("/stripe/overdraft");
        }
      })
      .catch((reason) => {
        this.log.error(reason);
      });
  }

  randomUUID() {
    // NOTE: why do we need this? Because of old browsers, and esp. fucking Safari 15, of course.
    if (window.crypto && window.crypto.randomUUID) {
      return window.crypto.randomUUID();
    } else {
      return uuid.v4();
    }
  }

  showRecommendedModal() {
    this.recommendedModalTarget.classList.remove("hidden");
  }

  hideRecommendedModal() {
    this.recommendedModalTarget.classList.add("hidden");
  }
}
