import { Controller } from "@hotwired/stimulus";

type Color = { r: number; g: number; b: number };

// NOTE: https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o

export default class extends Controller {
  static targets = ["sample"];
  declare readonly sampleTargets: HTMLTableCellElement[];

  connect() {
    this.sampleTargets.forEach((td) => {
      const fgStyle = getComputedStyle(td).color;
      const fgColor = this.colorFromStyle(fgStyle);
      const bgStyle = getComputedStyle(td).background;
      const bgColor = this.colorFromStyle(bgStyle);
      if (!fgColor || !bgColor) return;
      const fgLum = this.luminance(fgColor);
      const bgLum = this.luminance(bgColor);
      const ratio =
        fgLum > bgLum
          ? (bgLum + 0.05) / (fgLum + 0.05)
          : (fgLum + 0.05) / (bgLum + 0.05);
      td.dataset.ratio = (1 / ratio).toFixed(1);
    });
  }

  colorFromStyle(style: string): Color | undefined {
    const match = style.match(/rgb\((?<r>\d+), (?<g>\d+), (?<b>\d+)\)/);
    if (!match || !match.groups) return;
    return {
      r: parseInt(match.groups.r),
      g: parseInt(match.groups.g),
      b: parseInt(match.groups.b),
    };
  }

  luminance(color: Color) {
    var a = [color.r, color.g, color.b].map(function (v) {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }
}
