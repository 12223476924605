import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "../lib/util";

export default class extends Controller {
  static classes = ["on", "off", "busy"];

  onClass!: string;
  offClass!: string;
  busyClass!: string;

  static targets = ["button"];
  declare readonly buttonTargets: HTMLButtonElement[];

  static values = {
    url: String,
    status: Boolean,
  };
  urlValue!: string;
  statusValue!: boolean;

  connect() {
    this.toggleHighlightedButtons(this.statusValue);
  }

  toggleBusyButtons(force: boolean) {
    this.buttonTargets.forEach((element) => {
      element.disabled = force;
      element.classList.toggle(this.busyClass, force);
    });
  }

  toggleHighlightedButtons(force: boolean) {
    this.buttonTargets.forEach((element) => {
      element.classList.toggle(this.onClass, force);
      element.classList.toggle(this.offClass, !force);
    });
  }

  async sendRequest() {
    // TODO: throttle
    return await fetch(this.urlValue, {
      method: this.statusValue ? "DELETE" : "POST",
      headers: { "X-CSRF-Token": csrfToken() },
    });
  }

  async toggle(event: PointerEvent) {
    event.preventDefault();
    this.toggleBusyButtons(true);
    await this.sendRequest();
    this.statusValue = !this.statusValue;
    this.toggleHighlightedButtons(this.statusValue);
    this.toggleBusyButtons(false);
  }
}
